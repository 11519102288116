export const environment = {
  production: true,
  // agentURL: 'https://v2.agent.upswingaura.com',
  agentURL: 'https://dev.api.upswingaura.com',
  apiURL: 'https://rms-microservice-development-dot-aura-staging-31cae.el.r.appspot.com',
  firebaseConfig: {
    apiKey: "AIzaSyAWLnlSW2r-vs4JAceTcgUxaoDivw5zmZc",
    authDomain: "aura-staging-31cae.firebaseapp.com",
    databaseURL: "https://aura-staging-31cae.firebaseio.com",
    projectId: "aura-staging-31cae",
    storageBucket: "aura-staging-31cae.appspot.com",
    messagingSenderId: "346792340064",
    appId: "1:346792340064:web:427680a82f1774cd817105",
    measurementId: "G-9TE53RR2BL"
  }
};
