import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { NgxPermissionsService, NgxRolesService } from "ngx-permissions";
import { BehaviorSubject } from "rxjs";
import { Organization } from "../model/organization.model";
import { Role } from "../model/role.model";
import { User } from "../model/user.model";
import { PropertyService } from "./property.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private _userIsAuthenticated = false;
  private userDoc;
  private userID: any;
  private user$ = new BehaviorSubject<User>(null);
  private org$ = new BehaviorSubject<Organization>(null);
  private role$ = new BehaviorSubject<Role>(null);
  private _apiURL = new BehaviorSubject<string>(null);
  private _currencyCode = new BehaviorSubject<string>('USD');
  
  get userIsAuthenticated() {
    return this._userIsAuthenticated;
  }

  get userInfo() {
    return this.user$.asObservable();
  }

  get orgInfo() {
    return this.org$.asObservable();
  }

  get roleInfo() {
    return this.role$.asObservable();
  }

  get apiURL() {
    return this._apiURL.asObservable();
  }

  get currencyCode() {
    return this._currencyCode.asObservable();
  }

  constructor(
    private angularFire: AngularFireAuth,
    private afs: AngularFirestore,
    private propertyService: PropertyService,
    private permissionsService: NgxPermissionsService,
    private roleService: NgxRolesService,
    private router: Router
  ) {
    this.angularFire.onAuthStateChanged((user) => {
      if (user) {        
        this._userIsAuthenticated = true;
        this.userID = user.uid;
        this.userDoc = this.afs.doc<User>(`users/${user.uid}`);

        this.userDoc.valueChanges().subscribe(async (data: User) => {
          if (!data) {
            console.log('User profile not created');
            
          }
          this.propertyService.setPropertyListByRef(data.orgRef, data.client_id);
          // this.propertyService.setPropertyList(data.orgRef, data.client_id);
          await data.orgRef.get().then((snap: any) => {
            data.org = snap.data();
            this._apiURL.next(data.org.agentURL);
            this._currencyCode.next(data.org.currencyCode);
          });
          await data.roleRef.get().then((snap) => {
            const _role = snap.data();
            const perm_list = _role.permissions.map((x) => x.uid);
            data.role = {
              name: _role.name,
              uid: _role.uid,
              permissions: perm_list
            }
            this.roleService.addRole(_role.name, perm_list);
            this.permissionsService.loadPermissions(perm_list);
          });
          this.user$.next(data);
          // this.router.navigate(['/core']);
        });
      } else {
        this.user$.next(null);
      }
    });
  }

  async emailSigning(email, password) {
    await this.angularFire
      .signInWithEmailAndPassword(email, password)
      .then((val) => {
        return true;
      })
      .catch((err) => {
        throw err;
      });
  }

  async logout() {
    this.router.navigate(['/auth']);
    await this.angularFire
      .signOut()
      .then((val) => {
        this._userIsAuthenticated = false;
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
