import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";
import { Property } from "../model/property.model";
import { AngularFirestore, DocumentReference } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class PropertyService {
  private _propertyID: number = 0;
  private _propertyList: Property[] = null;
  private _isPropertyListReady: boolean = false;
  private _filteredPropertyList: number[] = null;
  private property$ = new BehaviorSubject<Property[]>(this._propertyList);
  private propertyReady$ = new BehaviorSubject<boolean>(
    this._isPropertyListReady
  );
  private filterProperty$ = new BehaviorSubject<number[]>(
    this._filteredPropertyList
  );
  private _propertyRefInFirestore: DocumentReference = null;

  get propertyList() {
    return this.property$.asObservable();
  }

  get filteredPropertyList() {
    return this.filterProperty$.asObservable();
  }

  get propertyReady() {
    return this.propertyReady$.asObservable();
  }

  setFilteredPropertyList(val: number[]) {
    this._filteredPropertyList = val;
    this.filterProperty$.next(val);
  }

  constructor(private http: HttpClient, private afs: AngularFirestore) {}

  get propertyID() {
    if (this._propertyID === 0) {
      this._propertyID = +localStorage.getItem("_propertyID");
    }
    return this._propertyID;
  }

  set propertyID(val: number) {
    this._propertyID = val;
    localStorage.setItem("_propertyID", "" + val);
  }

  async setPropertyListByRef(ref, client_ids: number[]) {
    this._propertyRefInFirestore = ref;
    this._propertyList = [];

    client_ids.forEach((id, index) => {
      const property_ref = ref.collection("properties").doc(String(id));
      property_ref.get().then((doc) => {
        if (
          doc.exists &&
          this._propertyList.findIndex((x) => x.clientId === id) === -1
        ) {
          const upData = doc.data();
          // // TODO : only for demo purpose
          // upData.name = upData.name.replace("Unicorn Groups - ", "Unicorn Groups ");
          // upData.name = upData.name.replace("Unicorn Groups – ", "Unicorn Groups ");
          // upData.name = upData.name.replace("FAMCO ", "Unicorn Groups ");
          // console.log(upData.name);

          this._propertyList.push(upData);
          if (index + 1 == client_ids.length) {
            this._propertyList = this._propertyList.sort((a, b) =>
              a._id > b._id ? 1 : -1
            );
            this.property$.next(this._propertyList);
            this.setFilteredPropertyList(client_ids);
            this._isPropertyListReady = true;
            this.propertyReady$.next(this._isPropertyListReady);
          }
        }
      });
    });
  }

  getPropertyInfo(clientId) {
    return this._propertyList.find((x) => x.clientId === clientId);
  }

  getPropertyID() {
    if (this._propertyID === 0) {
      this._propertyID = +localStorage.getItem("_propertyID");
    }
    return this._propertyID;
  }

  getOwnerProperties(emailID) {
    // const areaRef = this.afs.collectionGroup('areas', ref => ref.where('owner_email_list', 'array-contains', emailID));
    // return areaRef.valueChanges();
    // .then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //         // doc.data() is never undefined for query doc snapshots
    //         console.log(doc.id, " => ", doc.data());
    //     });
    // })
    // .catch((error) => {
    //     console.log("Error getting documents: ", error);
    // });
    return [
      {
        userDefined2: "",
        attributes: [
          {
            availableToIbe: false,
            associatedTo: "Area",
            id: 10,
            name: "2 Bedroom group",
          },
          {
            name: "Boulevard view",
            id: 3,
            associatedTo: "Area",
            availableToIbe: false,
          },
          {
            associatedTo: "Area",
            availableToIbe: false,
            name: "Community View",
            id: 40,
          },
        ],
        glCodeId: 110,
        description: "Open View 2U702",
        name: "Open View 2U702",
        maxOccupants: 4,
        printStatement: true,
        addressLine1: "Boulevard Crescent Tower 2",
        modifiedDate: "2020-12-14 17:58:21",
        userDefined8: "",
        userDefined6: "",
        userDefined3: "",
        userDefined10: "",
        userDefined7: "",
        keyNumber1: "702",
        guestDescription: "Deluxe Two Bedroom Apartment",
        propertyId: 8,
        inactive: false,
        createdDate: "1900-01-01 00:00:00",
        owner_email_list: ["matrixjlt@gmail.com"],
        owners: [
          {
            userDefined4: "",
            addressLine1: "",
            postCode: "215088",
            birthday: "1956-07-29 00:00:00",
            addressLine2: "",
            userDefined8: "",
            userDefined11: "",
            privacyOptIn: false,
            marketingOptOut: false,
            userDefined10: "",
            phoneAH: "",
            userDefined1: "",
            languageSpokenId: 2,
            edmFilter3OptOut: false,
            email2: "",
            userDefined6: "",
            userDefined12: "",
            userDefined15: "1900-01-01 00:00:00",
            userDefined9: "",
            id: 11901,
            phoneOptOut: false,
            userDefined14: false,
            town: "Dubai",
            companyId: 0,
            countryId: 172,
            mobile: "+971￼￼￼552036496",
            smsOptOut: false,
            edmFilter2OptOut: false,
            userDefined7: "",
            state: "",
            userDefined13: false,
            guestGiven: "Mohammad Azzam",
            emailOptOut: false,
            notes: "",
            email: "matrixjlt@gmail.com",
            passportId: "53 0816773",
            propertyId: 8,
            addressLine3: "",
            nationalityId: 172,
            guestSurname: "Sued",
            edmFilter1OptOut: false,
            userDefined3: "",
            title: "Mr",
            userDefined16: "1900-01-01 00:00:00",
            userDefined2: "",
            userDefined5: "",
          },
        ],
        categoryId: 110,
        addressLine3: "Dubai",
        externalRef: "",
        userDefined5: "",
        id: 245,
        userDefined4: "",
        interconnecting: false,
        addressLine2: "Downtown Dubai",
        propertyName: "Royal Heights",
        keyNumber2: "",
        googleMapLink: "",
        userDefined9: "",
        statisticsStatus: true,
        userDefined1: "",
        propertyClientID: 11347,
        state: "Dubai",
        _id: 245,
        postCode: "215088",
        extension: "",
        cleanStatus: "Vacant Clean",
        town: "Dubai",
      },
      {
        cleanStatus: "Occupied",
        userDefined3: "",
        id: 246,
        externalRef: "",
        addressLine2: "Downtown Dubai",
        owner_email_list: ["matrixjlt@gmail.com"],
        userDefined4: "",
        userDefined2: "",
        propertyId: 8,
        propertyClientID: 11347,
        statisticsStatus: true,
        userDefined10: "",
        guestDescription: "Deluxe One Bedroom Apartment",
        keyNumber1: "804",
        state: "Dubai",
        modifiedDate: "2020-12-14 18:00:10",
        userDefined8: "",
        town: "Dubai",
        userDefined9: "",
        keyNumber2: "",
        userDefined1: "",
        glCodeId: 110,
        addressLine1: "Boulevard Crescent Tower 2",
        addressLine3: "Dubai",
        _id: 246,
        createdDate: "1900-01-01 00:00:00",
        userDefined6: "",
        googleMapLink: "",
        name: "Open View 2U804",
        attributes: [
          {
            availableToIbe: false,
            id: 9,
            associatedTo: "Area",
            name: "1 Bedroom group",
          },
          {
            associatedTo: "Area",
            id: 3,
            availableToIbe: false,
            name: "Boulevard view",
          },
          {
            name: "Community View",
            associatedTo: "Area",
            id: 40,
            availableToIbe: false,
          },
        ],
        printStatement: true,
        interconnecting: false,
        inactive: false,
        description: "Open View 2U804",
        propertyName: "Royal Heights",
        postCode: "215088",
        maxOccupants: 2,
        owners: [
          {
            edmFilter3OptOut: false,
            nationalityId: 172,
            birthday: "1956-07-29 00:00:00",
            addressLine2: "",
            userDefined7: "",
            userDefined14: false,
            emailOptOut: false,
            propertyId: 8,
            userDefined13: false,
            countryId: 172,
            state: "",
            userDefined15: "1900-01-01 00:00:00",
            passportId: "53 0816773",
            privacyOptIn: false,
            companyId: 0,
            phoneAH: "",
            marketingOptOut: false,
            addressLine1: "",
            userDefined4: "",
            languageSpokenId: 2,
            title: "Mr",
            edmFilter1OptOut: false,
            userDefined10: "",
            userDefined8: "",
            guestGiven: "Mohammad Azzam",
            guestSurname: "Sued",
            userDefined9: "",
            addressLine3: "",
            edmFilter2OptOut: false,
            smsOptOut: false,
            postCode: "215088",
            userDefined1: "",
            town: "Dubai",
            userDefined6: "",
            userDefined16: "1900-01-01 00:00:00",
            phoneOptOut: false,
            email: "matrixjlt@gmail.com",
            notes: "",
            userDefined3: "",
            mobile: "+971￼￼￼552036496",
            userDefined2: "",
            email2: "",
            userDefined5: "",
            id: 11901,
            userDefined11: "",
            userDefined12: "",
          },
        ],
        extension: "",
        userDefined7: "",
        userDefined5: "",
        categoryId: 111,
      },
      {
        createdDate: "1900-01-01 00:00:00",
        userDefined10: "",
        owner_email_list: ["matrixjlt@gmail.com"],
        userDefined7: "",
        postCode: "215088",
        keyNumber2: "",
        glCodeId: 110,
        addressLine2: "DIFC",
        owners: [
          {
            privacyOptIn: false,
            userDefined5: "",
            languageSpokenId: 2,
            addressLine3: "",
            userDefined11: "",
            state: "",
            userDefined13: false,
            edmFilter2OptOut: false,
            userDefined7: "",
            edmFilter3OptOut: false,
            companyId: 0,
            userDefined14: false,
            userDefined12: "",
            userDefined10: "",
            countryId: 172,
            title: "Mr",
            guestGiven: "Mohammad Azzam",
            smsOptOut: false,
            postCode: "215088",
            edmFilter1OptOut: false,
            nationalityId: 172,
            id: 11901,
            guestSurname: "Sued",
            userDefined16: "1900-01-01 00:00:00",
            phoneOptOut: false,
            town: "Dubai",
            birthday: "1956-07-29 00:00:00",
            addressLine1: "",
            mobile: "+971￼￼￼552036496",
            emailOptOut: false,
            userDefined2: "",
            userDefined3: "",
            marketingOptOut: false,
            email: "matrixjlt@gmail.com",
            userDefined1: "",
            addressLine2: "",
            userDefined15: "1900-01-01 00:00:00",
            email2: "",
            passportId: "53 0816773",
            userDefined4: "",
            userDefined9: "",
            userDefined8: "",
            userDefined6: "",
            phoneAH: "",
            propertyId: 8,
            notes: "",
          },
        ],
        propertyId: 8,
        externalRef: "",
        modifiedDate: "2020-12-14 18:08:49",
        propertyName: "Royal Heights",
        attributes: [
          {
            id: 10,
            name: "2 Bedroom group",
            availableToIbe: false,
            associatedTo: "Area",
          },
          {
            name: "Community View",
            availableToIbe: false,
            associatedTo: "Area",
            id: 40,
          },
        ],
        googleMapLink: "",
        userDefined4: "",
        interconnecting: false,
        userDefined8: "",
        printStatement: true,
        description: "Royal 5004",
        propertyClientID: 11347,
        id: 247,
        cleanStatus: "Occupied",
        keyNumber1: "5004",
        userDefined2: "",
        state: "Dubai",
        guestDescription: "Deluxe Two Bedroom Apartment",
        userDefined1: "",
        extension: "",
        userDefined9: "",
        inactive: false,
        categoryId: 113,
        addressLine3: "Dubai",
        town: "Dubai",
        addressLine1: "Royal  Daman",
        userDefined5: "",
        statisticsStatus: true,
        userDefined6: "",
        name: "Royal 5004",
        userDefined3: "",
        _id: 247,
        maxOccupants: 4,
      },
      {
        guestDescription: "Deluxe One Bedroom Apartment",
        userDefined5: "",
        addressLine1: "Royal  Daman",
        userDefined3: "",
        propertyClientID: 11347,
        owners: [
          {
            passportId: "53 0816773",
            marketingOptOut: false,
            userDefined10: "",
            languageSpokenId: 2,
            notes: "",
            nationalityId: 172,
            edmFilter3OptOut: false,
            userDefined11: "",
            title: "Mr",
            email: "matrixjlt@gmail.com",
            addressLine3: "",
            userDefined7: "",
            countryId: 172,
            postCode: "215088",
            userDefined14: false,
            userDefined3: "",
            emailOptOut: false,
            userDefined8: "",
            edmFilter1OptOut: false,
            userDefined16: "1900-01-01 00:00:00",
            edmFilter2OptOut: false,
            companyId: 0,
            userDefined2: "",
            id: 11901,
            birthday: "1956-07-29 00:00:00",
            userDefined5: "",
            addressLine1: "",
            guestSurname: "Sued",
            phoneOptOut: false,
            userDefined6: "",
            guestGiven: "Mohammad Azzam",
            phoneAH: "",
            userDefined15: "1900-01-01 00:00:00",
            userDefined13: false,
            privacyOptIn: false,
            addressLine2: "",
            userDefined4: "",
            userDefined12: "",
            state: "",
            propertyId: 8,
            userDefined9: "",
            smsOptOut: false,
            mobile: "+971￼￼￼552036496",
            email2: "",
            town: "Dubai",
            userDefined1: "",
          },
        ],
        printStatement: true,
        cleanStatus: "Occupied",
        keyNumber2: "",
        town: "Dubai",
        description: "Royal 5002",
        id: 248,
        userDefined10: "",
        userDefined9: "",
        extension: "",
        name: "Royal 5002",
        postCode: "215088",
        userDefined2: "",
        googleMapLink: "",
        userDefined8: "",
        createdDate: "1900-01-01 00:00:00",
        userDefined6: "",
        addressLine3: "Dubai",
        _id: 248,
        state: "Dubai",
        userDefined4: "",
        categoryId: 112,
        modifiedDate: "2020-12-14 18:06:39",
        propertyName: "Sea View Resort",
        attributes: [
          {
            associatedTo: "Area",
            id: 9,
            name: "1 Bedroom group",
            availableToIbe: false,
          },
          {
            id: 40,
            associatedTo: "Area",
            name: "Community View",
            availableToIbe: false,
          },
        ],
        userDefined7: "",
        interconnecting: false,
        glCodeId: 110,
        maxOccupants: 0,
        inactive: false,
        externalRef: "",
        userDefined1: "",
        owner_email_list: ["matrixjlt@gmail.com"],
        addressLine2: "DIFC",
        statisticsStatus: true,
        propertyId: 8,
        keyNumber1: "5002",
      },
      {
        extension: "",
        userDefined6: "",
        glCodeId: 110,
        addressLine3: "Dubai",
        guestDescription: "Deluxe Two Bedroom Apartment",
        name: "King 1U1006A",
        createdDate: "1900-01-01 00:00:00",
        propertyClientID: 11347,
        userDefined8: "",
        keyNumber2: "",
        _id: 249,
        maxOccupants: 0,
        state: "Dubai",
        categoryId: 114,
        userDefined5: "",
        modifiedDate: "2021-01-09 14:15:41",
        inactive: false,
        interconnecting: false,
        postCode: "215088",
        propertyId: 8,
        userDefined4: "",
        statisticsStatus: true,
        userDefined2: "",
        cleanStatus: "Occupied",
        attributes: [
          {
            availableToIbe: false,
            id: 10,
            name: "2 Bedroom group",
            associatedTo: "Area",
          },
          {
            id: 3,
            name: "Boulevard view",
            associatedTo: "Area",
            availableToIbe: false,
          },
          {
            availableToIbe: false,
            id: 40,
            name: "Community View",
            associatedTo: "Area",
          },
        ],
        userDefined10: "",
        owner_email_list: ["matrixjlt@gmail.com"],
        userDefined3: "",
        addressLine2: "Downtown Dubai",
        keyNumber1: "1006",
        googleMapLink: "",
        externalRef: "",
        propertyName: "Royal Heights",
        description: "King 1U1006",
        owners: [
          {
            userDefined15: "1900-01-01 00:00:00",
            propertyId: 8,
            userDefined12: "",
            phoneOptOut: false,
            nationalityId: 172,
            state: "",
            addressLine3: "",
            edmFilter1OptOut: false,
            emailOptOut: false,
            userDefined16: "1900-01-01 00:00:00",
            addressLine1: "",
            userDefined2: "",
            postCode: "215088",
            birthday: "1956-07-29 00:00:00",
            addressLine2: "",
            userDefined4: "",
            email2: "",
            languageSpokenId: 2,
            marketingOptOut: false,
            userDefined13: false,
            userDefined9: "",
            userDefined8: "",
            notes: "",
            edmFilter2OptOut: false,
            privacyOptIn: false,
            userDefined5: "",
            phoneAH: "",
            guestGiven: "Mohammad Azzam",
            email: "matrixjlt@gmail.com",
            userDefined6: "",
            mobile: "+971￼￼￼552036496",
            userDefined1: "",
            id: 11901,
            town: "Dubai",
            userDefined7: "",
            passportId: "53 0816773",
            guestSurname: "Sued",
            edmFilter3OptOut: false,
            smsOptOut: false,
            countryId: 172,
            userDefined3: "",
            userDefined14: false,
            companyId: 0,
            title: "Mr",
            userDefined11: "",
            userDefined10: "",
          },
        ],
        addressLine1: "BLVD Heights Tower 1",
        printStatement: true,
        userDefined1: "",
        userDefined9: "",
        town: "Dubai",
        userDefined7: "",
        id: 249,
      },
      {
        googleMapLink: "",
        description: "King 1U1206",
        postCode: "215088",
        createdDate: "1900-01-01 00:00:00",
        _id: 250,
        categoryId: 114,
        propertyClientID: 11347,
        cleanStatus: "Occupied",
        addressLine1: "BLVD Heights Tower 1",
        town: "Dubai",
        interconnecting: false,
        name: "King 1U1206",
        owners: [
          {
            birthday: "1956-07-29 00:00:00",
            userDefined7: "",
            id: 11901,
            edmFilter2OptOut: false,
            postCode: "215088",
            smsOptOut: false,
            mobile: "+971￼￼￼552036496",
            guestGiven: "Mohammad Azzam",
            title: "Mr",
            userDefined11: "",
            userDefined12: "",
            emailOptOut: false,
            marketingOptOut: false,
            privacyOptIn: false,
            userDefined5: "",
            email2: "",
            propertyId: 8,
            userDefined10: "",
            guestSurname: "Sued",
            userDefined3: "",
            state: "",
            userDefined14: false,
            companyId: 0,
            edmFilter1OptOut: false,
            userDefined8: "",
            countryId: 172,
            userDefined6: "",
            userDefined9: "",
            userDefined2: "",
            addressLine2: "",
            userDefined13: false,
            phoneAH: "",
            edmFilter3OptOut: false,
            userDefined4: "",
            userDefined16: "1900-01-01 00:00:00",
            town: "Dubai",
            notes: "",
            phoneOptOut: false,
            passportId: "53 0816773",
            email: "matrixjlt@gmail.com",
            userDefined15: "1900-01-01 00:00:00",
            languageSpokenId: 2,
            addressLine1: "",
            userDefined1: "",
            nationalityId: 172,
            addressLine3: "",
          },
        ],
        printStatement: true,
        userDefined10: "",
        modifiedDate: "2020-12-14 18:04:47",
        keyNumber2: "",
        propertyName: "Royal Heights",
        addressLine2: "Downtown Dubai",
        userDefined3: "",
        owner_email_list: ["matrixjlt@gmail.com"],
        userDefined7: "",
        userDefined4: "",
        userDefined2: "",
        userDefined6: "",
        userDefined1: "",
        inactive: false,
        statisticsStatus: true,
        glCodeId: 110,
        guestDescription: "Deluxe Two Bedroom Apartment",
        extension: "",
        id: 250,
        maxOccupants: 0,
        propertyId: 8,
        addressLine3: "Dubai",
        userDefined8: "",
        externalRef: "",
        keyNumber1: "1206",
        userDefined9: "",
        state: "Dubai",
        attributes: [
          {
            id: 10,
            availableToIbe: false,
            associatedTo: "Area",
            name: "2 Bedroom group",
          },
          {
            name: "Boulevard view",
            availableToIbe: false,
            associatedTo: "Area",
            id: 3,
          },
          {
            name: "Community View",
            availableToIbe: false,
            associatedTo: "Area",
            id: 40,
          },
        ],
        userDefined5: "",
      },
    ];
  }

  getPropertyByID(_id: number) {
    return this._propertyList.find((x) => x._id === _id);
  }

  getProperties(body) {
    return this.http.post(
      `${environment.agentURL}/api/property/all_property`,
      body
    );
  }

  getPropertyDetails(body) {
    // return this.http.post(`${environment.apiURL}/property/details`, body);
    return this.http.get("assets/data/propertyDetails.json");
  }

  getPropertyStatus(body) {
    // return this.http.post(`${environment.apiURL}/property/status`, body);
    return this.http.get("assets/data/propertyStatus.json");
  }

  getAreas(body) {
    // return this.http.post(`${environment.apiURL}/property/areas/list`, body);
    return this.http.get("assets/data/propertyAreaList.json");
  }

  getOwnerAreas(body) {
    return this.http.post(`${environment.apiURL}/owner/areas/list`, body);
  }

  getOwnerRooms() {
    return this.http.get(`${environment.apiURL}/owner/areas/list`);
  }

  getAreaDetails(body) {
    // return this.http.post(`${environment.apiURL}/property/areas/details`, body);
    return this.http.get("assets/data/propertyAreaDetails.json");
  }

  getAreaRevenueTrend(body) {
    // return this.http.post(`${environment.apiURL}/property/areas/revenue`, body);
    return this.http.get("assets/data/propertyAreaRevenue.json");
  }

  getAreaAlos(area_list: number[]) {
    const body = {
      area_list: area_list,
    };
    return this.http.post(`${environment.apiURL}/property/area/alos`, body);
  }

  getPropertyInsights(id, date) {
    return this.http.get("assets/data/property-insights.json");
  }

  getDateDrilldown(body) {
    return this.http.post(
      environment.agentURL + "/api/property/date_coverage",
      body
    );
  }

  getPropertyReviews(id) {
    const body = {
      _propertyID: id,
      from: 0,
      to: 100,
      filter: {},
      sort: {},
    };
    // return this.http.post(this.baseIP + "/api/property/reviews", body);
  }

  getPropertyCoverage(body) {
    return this.http.post(
      environment.agentURL + "/api/property/coverage",
      body
    );
  }

  getPropertyReservationDistribution(body) {
    // return this.http.post(`${environment.apiURL}/reservation/status/distribution`, body);
    return this.http.get("assets/data/bookingDistribution.json");
  }

  getPropertyAddons(body) {
    return this.http.post(environment.agentURL + "/api/property/add_ons", body);
  }

  getGeoCoverage(body) {
    return this.http.post(
      `${environment.apiURL}/property/geoDistribution`,
      body
    );
  }

  getRevenueDistribution(body) {
    // return this.http.post(`${environment.apiURL}/transaction/coverage`, body);
    return this.http.get("assets/data/transactionCoverage.json");
  }

  getRateType(body) {
    return this.http.post(
      environment.agentURL + "/api/transaction/rate_distribution",
      body
    );
  }

  getRevenueByProperty(body) {
    // return this.http.post(`${environment.apiURL}/transaction/revenue/property`,body);
    if (body.client_id.length > 1) {
      return this.http.get("assets/data/allPropertyRevenue.json");
    }
    return this.http.get("assets/data/propertyRevenue.json");
  }

  // getRevenueTrend(body) {
  //   return this.http.post(
  //     environment.agentURL + "/api/transaction/property_revenue",
  //     body
  //   );
  // }

  getRevenueTrend(body) {
    // return this.http.post(`${environment.apiURL}/analytics/property/trend`, body);
    return this.http.get("assets/data/propertyPerformanceTrend.json");
  }

  getRevenueTrendARRRevPAR(body) {
    // return this.http.post(`${environment.apiURL}/analytics/property/trend`, body);
    return this.http.get("assets/data/propertyPerformanceTrendARRRevPAR.json");
  }

  getSinglePropertyRevenueTrend(body) {
    return this.http.post(
      environment.agentURL + "/api/transaction/revenue_trend",
      body
    );
  }

  getDataCoverage(body) {
    return this.http.post(
      environment.agentURL + "/api/property/data_coverage",
      body
    );
  }

  getVoidTransactions(body) {
    // return this.http.post(`${environment.apiURL}/transaction/void`, body);
    return this.http.get("assets/data/propertyVoid.json");
  }

  getAllOffers(body) {
    return this.http.post(environment.agentURL + "/api/property/offers", body);
  }

  getQuarterlyStats(body) {
    // return this.http.post(`${environment.apiURL}/analytics/property/performance`,body);
    return this.http.get("assets/data/propertyPerformance.json");
  }

  getOccupancyVsRevenue(body) {
    // return this.http.post(`${environment.apiURL}/analytics/property/performance`,body);
    return this.http.get("assets/data/graph/occupancyVSrevenue.json");
  }
}
